hr{
    border: none;
    width: 100%;
    height: 3px;
    background-color: #0041be;
    border-radius: 10px;
    position: relative;
    top: 10px;
}



@media screen and (max-width: 1023px) {


  
    .menu{
      position: fixed;
      top: 0rem;
      right: 0rem;
      background-color: white;
      display: flex;
      justify-content: center;
      flex-direction: column;
      z-index: 1000;
      width: 55vw;
      min-height: 60%;
      border-bottom-left-radius: 10px;
      gap: .5rem;
      padding: 5rem 0;
      text-align: center;
      font-size: 16px;
      box-shadow: 5px 3px 6px 5px rgb(58, 56, 56);
      transition: all 300ms ease-in;
      transform: translateX(100%);
      /* backdrop-filter: blur(10px); */
    }
    
    .responsive_nav{
      transform: none;
    }

    hr{
      display: none;
    }
  }



