@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Poppins:wght@400;500;600;700&family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Roboto+Slab:wght@100..900&family=Varela+Round&display=swap');




@tailwind base;
@tailwind components;
@tailwind utilities;


/* clip-path: polygon(0 0, 100% 0, 100% 80%, 50% 45%, 0 80%); */




.anim {
    animation: slideInLeft 1.2s ease-out;
    animation-delay: 0;
}

@keyframes slideInLeft {

    from {
        transform: translateX(-20vw);
    }

    to {
        transform: translateX(0);
    }
}

.animr {
    animation: slideInRight 1.2s ease-out;
    animation-delay: 0;
}

@keyframes slideInRight {

    from {
        transform: translateX(20vw);
    }

    to {
        transform: translateX(0);
    }
}

.animLine {
    animation: lineIn 1s ease-in;
}

@keyframes lineIn {

    from {
        width: 0%;
    }

    to {
        width: 47%;
    }
}

.animateOpacity {
    animation: slideInOpacity 1s ease-in;
    animation-delay: 0;
}

@keyframes slideInOpacity {

    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.animate-heart{
  animation: beat 5s ease-out infinite;
  animation-delay: 0;
  
}

@keyframes beat {
  0% {
    transform: scale(1.0);
  }

  50% {
    transform: scale(1.05);
  }
  
}

.animate-move{
    animation: around 5s ease-in-out infinite;
    animation-delay: 0;
}

@keyframes around {
    0% {
        transform: translateX(0%);
    }

    50% {
        transform: translateX(-100%);
    }
}

.animate-moveR{
    animation: aroundR 5s ease-in-out infinite;
    animation-delay: 0;
}

@keyframes aroundR {
    0% {
        transform: translateX(0%);
    }

    50% {
        transform: translateX(100%);
    }
}

.shadow{
  box-shadow: -15px 10px 37px 4px rgba(0,167,221,0.27);
  -webkit-box-shadow: -15px 10px 37px 4px rgba(0,167,221,0.27);
  -moz-box-shadow: -15px 10px 37px 4px rgba(0,167,221,0.27);
}

input:focus{
  background: none;
}

@media screen and (max-width: 1023px) {
    .animationUp{
        animation: slideInUp 1.2s ease-out;
        animation-delay: 0;
    }
    
    @keyframes slideInUp {
        from {
            transform: translateY(10vh);
            opacity: 0;
        }
    
        to {
            transform: translateY(0);
            opacity: 1;
        }
    }

    .anim{
        animation: none;
    }

    .animr{
        animation: none;
    }

    .animate-move{
        animation: around 5s ease-in-out infinite;
        animation-delay: 0;
    }
    
    @keyframes around {
        0% {
            transform: translateX(0%);
        }
    
        50% {
            transform: translateX(-20px);
        }
    }
    
    .animate-moveR{
        animation: aroundR 5s ease-in-out infinite;
        animation-delay: 0;
    }
    
    @keyframes aroundR {
        0% {
            transform: translateX(0px);
        }
    
        50% {
            transform: translateX(20px);
        }
    }
}



