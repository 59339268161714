section{
    background-image: url('../Assets/bear.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100vw;
    position: relative;
    z-index: 1;
}

section::after{
    content: '';
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: .8;
    /* background-color: black; */
    background-image: url('../Assets/11.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.curved-line {
    width: 300px;
    height: 100px;
    margin: -4rem 0 0 1rem;
    border-radius: 50%;
    background: transparent;
    border: 6px solid;
    border-color: #00A7DD transparent transparent transparent;
  }


  
